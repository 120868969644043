import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "monitor/4Kmonitor"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    fourk_fullhd: file(relativePath: { eq: "monitor/fourk_fullhd.png"}) { ...normalImg },
    multiview: file(relativePath: { eq: "monitor/multiview.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`4Kモニターによる複数画面出力`}</h1>
    <Socials {...props} mdxType="Socials" />
    <p>{`解像度3840*2160というフルHDの縦横それぞれ2倍ずつ、計4倍のドット数を誇る高画質なモニターが`}<strong parentName="p">{`4Kモニター`}</strong>{`である。このモニターを使う事により従来の4倍の表示領域を確保する事ができる。`}</p>
    <Image {...props} name="fourk_fullhd" alt="フルHDと4Kの一般的なWebサイトの映り方の違い" mdxType="Image" />
    <p>{`即ち物理的に同じ画面サイズの２つモニタがあり、片方がフルHD、片方が4Kモニタであったとするとそれぞれ上記のように映る。画面に映るWebサイトの領域が小さく、閲覧するには解像度が高すぎて困る場合がある。`}</p>
    <p>{`例えば、仮にWebサイトの画面を最大化させると、Webサイトの横幅は1000px程度が主流なので、4Kだと4分の3の部分が空白になってしまう。`}</p>
    <p>{`このためマウスでそれぞれのWindowのサイズを微調整させながら複数の画面を最適な位置に配置していくという面倒くさい作業が発生してしまい使いづらい。よって動画や写真を高画質で閲覧する、ゲームをするなどが4Kモニターのメインの使い方となるかもしれない。`}</p>
    <p>{`しかし、`}<strong parentName="p">{`複数のソースからの同時画面出力を行うマルチビュー機能`}</strong>{`により、最大４画面を同時に出力する事が可能で、これを行いマルチモニター化する事で今まで以上にパソコン操作を快適に行う事ができる。`}</p>
    <Image {...props} name="multiview" alt="4KモニターをFullHDの4モニター分として使う" mdxType="Image" />
    <p>{`例えば、Window1とWindow2をデスクトップPCからの出力、Window3をノートパソコン、Window4をゲーム機用の出力といった使い方が可能である。基本的にノートパソコンであれデスクトップパソコンであれ2画面への出力は可能であり、デュアルディスプレイの構築は可能であるが、一つのパソコンから４画面出力となると端子の数が足りないなど対応できない場合がほとんどである。４画面出力を行うためには2つ方法があり、`}</p>
    <ol>
      <li>4画面に対して出力を行えるグラフィックボードを購入する。</li>
      <li>2画面以上に対して出力を行えるグラフィックボードを購入し、オンボードの出力機能と併用して4画面出力を行う。</li>
    </ol>
    <p>{`のいずれかである。このうち１の方法はハイエンドなグラフィックボードを購入する必要があり、グラフィック性能を要するゲームを行いたい時などを除き不要である。`}</p>
    <p>{`一応解説しておくと、例えば`}<a parentName="p" {...{
        "href": "https://amzn.to/3qrrNtm",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`RTX 3060`}</a>{`はDisplayPort３つとHDMIポート１つの計４つの出力端子を備えており、それぞれに対して出力を行う事ができる。接続端子が合わない場合は変換ケーブルを用意してやる必要がある。基本的にはポートの数が最大画面数（同時に出力できる最大の画面数)と同じになる事が多いが、例えばポート数が５であっても4画面同時出力までしか対応していない場合もあるので注意が必要である。ともあれ特に説明がなされていない場合はポートの数を最大画面数と考えて良さそうである。`}</p>
    <p>{`より現実的に安価で済ませるには２のグラフィックボードとオンボードの両方を用いる方法を取る。普通にコネクタを繋げれば４画面が表示されることが大半であるが、BIOS(UEFI)の設定を行うことが必要なケースもあり、ざっくりとは次の手順で行う。`}</p>
    <ol>
      <li>パソコンを起動した瞬間にdelキーあるいはF2キーを連打してパソコンのハードウェア制御を行うBIOS(FEFI)の画面を立ち上げる。</li>
      <li>キー操作で、Advanced -> System Agent Configuration -> Graphics Configurationまで進み、IGPU Multi-MonitorをEnabled(有効)にする。(メーカーにより若干表記の差異はあるかもしれないので悪しからず)</li>
    </ol>
    <p>{`Intel 5シリーズ以前のIntelチップセットを使用している場合はこの方法が使用できないらしいが、このチップセットの発売は2008/2009と非常に古いため、最近のパソコンを持っている人はまずこの方法が使えるはずだ。`}</p>
    <p>{`ところで4Kモニターに接続して4Kの解像度を実際に出力するためにはDisplayPortかHDMI1.4以上に対応している必要がある。またグラフィックボードのサイトに行き仕様を確認すると最大デジタル解像度の項目があり、それが4320*2160以上になっている事が４Kで出力するための条件である。`}</p>
    <p>{`よほど古いグラフィックボードを使っていない限りは最大デジタル解像度の問題はないだろう。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      